import { useStoreConfig } from '@/stores/config'

//vueファイル一覧を取得
const vueModules = require.context('@/views', true, /.*_*\.vue/,'lazy')
//componentファイル一覧を取得
const componentModules = require.context('@/components', true, /.*_*\.vue/,'lazy')
//ドメイン名
let domainName = ''

//ドメイン名付けのvueファイルがある場合、それを取得する
export const getVueName = (fileName) => {
  if(!domainName){
    const config = useStoreConfig();
    domainName = config.templateDomainName;
  }
  for(let item of vueModules.keys()){
    item = item.replace('./', '')
    if(item.toLowerCase() == fileName.replace('.vue','_' + domainName +'.vue').toLowerCase()){
      return item
    }
  }

  return fileName
}

//ドメイン名付けのcomponentファイルがある場合、それを取得する
export const getComponentName = (fileName) => {
  if(!domainName){
    const config = useStoreConfig();
    domainName = config.templateDomainName;
  }
  for(let item of componentModules.keys()){
    item = item.replace('./', '')
    if(item.toLowerCase() == fileName.replace('.vue','_' + domainName +'.vue').toLowerCase()){
      return item
    }
  }

  return fileName
}

