import { defineStore } from 'pinia'

//サイト設定用データストア
export const useStoreConfig = defineStore('config', {
  state: () => ({
    domainName: '',
    templateDomainName: '',
    tenantId: '',
    apiOriginName: '',
    apikey: '',
    lotteryOnTheTopId: '',
    lotOnTopSetting: '',
    underMaintenance: '',
    maintenanceFrom: '',
    maintenanceTo: '',
    cookieAgreement: '',
    direction: '',
    siteName: '',
    title: '',
    keywords: '',
    description: '',
    adultCheck: '',
    xAccount: '',
    purchaseTimeout: '',
    pointSystem: '',
    shopId: '',
    paymentHost: '',
    invoice: '',
    receipt: '',
    sbPaymentUrlBase: '',
  }),
  actions: {
    //ドメイン名
    setDomainName(domainName) {
      this.domainName = domainName
    },
    setTemplateDomainName(templateDomainName) {
      this.templateDomainName = templateDomainName
    },
    //システム設定
    setSystemSettings(systemSettings) {
      this.tenantId= systemSettings.tenantId
      // this.tenantId= 4
      this.apiOriginName= systemSettings.apiOriginName
      this.apikey= systemSettings.apikey
      // this.apikey= "acwjkvbewvewjkvwjke"
    },
    //テナント設定
    setTenantSettings(tenantSettings) {
      this.lotteryOnTheTopId= tenantSettings.lotteryOnTheTopId
      this.lotOnTopSetting= tenantSettings.lotOnTopSetting
      this.underMaintenance= tenantSettings.underMaintenance
      this.maintenanceFrom= tenantSettings.maintenanceFrom
      this.maintenanceTo= tenantSettings.maintenanceTo
      this.cookieAgreement= tenantSettings.cookieAgreement
      this.direction= tenantSettings.direction
      this.siteName= tenantSettings.siteName
      this.title= tenantSettings.title
      this.keywords= tenantSettings.keywords
      this.description= tenantSettings.description
      this.adultCheck= tenantSettings.adultCheck
      this.xAccount= tenantSettings.xaccount
      this.purchaseTimeout= tenantSettings.purchaseTimeout
      this.pointSystem = tenantSettings.pointSystem
      this.shopId = tenantSettings.shopId
      this.paymentHost = tenantSettings.paymentHost
      this.invoice = tenantSettings.invoice?tenantSettings.invoice:0
      this.receipt = tenantSettings.receipt?tenantSettings.receipt:0
      this.sbPaymentUrlBase = tenantSettings.sbPaymentUrlBase
    }
  }
})