<template>
<body class="<%= yield :body_class %>">
  <template v-if = "!config.underMaintenance && config.adultCheck && !userInfo.ageChecked">
    <router-view name="window"/>
  </template>
  <template v-else>
  <nav>
    <Header/>
    <div id="container" class="turning_point">
      <!-- ページヘッダー -->
      <PageHead/>
      <!-- エラーメッセージ -->
      <MessagesBlock/>
      <!-- ページ本体 -->
      <router-view/>
      <!-- パンくずリスト -->
      <BreadCrumb/>
    </div>
    <Footer/>
  </nav>
  </template>

</body>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent } from 'vue'
import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'
import { useStoreConfig } from '@/stores/config'
import { useStoreMetaInfo } from '@/stores/metaInfo'
import { useStoreUserInfo } from '@/stores/userInfo'
import { getImage } from './utils/imageUtil'
import { getComponentName } from '@/utils/vueUtil'
import PageHead from '@/components/PageHead.vue'
import MessagesBlock from '@/components/MessagesBlock.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'
const Header = defineAsyncComponent(() =>import('@/components/' + getComponentName('header/MainHeader.vue')))
const Footer = defineAsyncComponent(() =>import('@/components/' + getComponentName('footer/MainFooter.vue')))

const route = useRoute()
const userInfo = useStoreUserInfo()
const config = useStoreConfig()
const metaInfo = useStoreMetaInfo()

const metaTitle = ref('')
const metaOgType = ref('')
const metaOgTitle = ref('')
const metaOgDescription = ref('')
const metaOgImage = ref('')
const metaOgUrl = ref('')
const metaOgSiteName = ref('')

useHead({
  title: metaTitle,
  meta: [
    { property: 'og:type', content: metaOgType },
    { property: 'og:title', content: metaOgTitle },
    { property: 'og:description', content: metaOgDescription },
    { property: 'og:image', content: metaOgImage },
    { property: 'og:url', content: metaOgUrl },
    { property: 'og:site_name', content: metaOgSiteName },
  ],
})


//meta設定
function setMeta() {
  // タイトルを設定
  if(metaInfo.title && route.path == '/') {
    metaTitle.value = config.siteName + ' | ' + metaInfo.title
  } 
  else {
    metaTitle.value = metaInfo.title + ' | ' + config.siteName
  }

  // ディスクリプションを設定
  if(metaInfo.description){
    document.querySelector("meta[name='description']").setAttribute('content', metaInfo.description)
    metaOgDescription.value = metaInfo.description
  }
  
  // キーワードを設定
  if(metaInfo.keywords){
    document.querySelector("meta[name='keywords']").setAttribute('content', metaInfo.keywords)
  }

  // // 画像を設定
  if(metaInfo.image){
    metaOgImage.value = metaInfo.image
    document.querySelector("meta[name='twitter:image'").setAttribute('content', metaInfo.image)
  }
}

//Seo用metaを設定
function setSeo() {
  //サイトのURL
  const url = (new URL(window.location.href)).origin

  //facebook
  metaOgType.value = 'article'
  metaOgTitle.value = config.siteName + ' | ' + config.title
  metaOgDescription.value = config.description
  metaOgUrl.value = url
  metaOgImage.value = getImage('common/ogp/ogp.jpg')
  metaOgSiteName.value = config.siteName

  //twitter
  document.querySelector("meta[name='twitter:site']").setAttribute('content', config.xAccount)
  document.querySelector("meta[name='twitter:card']").setAttribute('content', 'summary_large_image')
  document.querySelector("meta[name='twitter:title']").setAttribute('content', config.title)
  document.querySelector("meta[name='twitter:description'").setAttribute('content', config.description)
  document.querySelector("meta[name='twitter:image'").setAttribute('content', getImage('common/ogp/ogp.jpg'))
  document.querySelector("meta[name='twitter:url'").setAttribute('content', url)
}

onMounted( () => {
  //アイコン(link rel="icon")を設定
  const link = document.querySelector("link[rel*='icon']")
  link.href = getImage("favicon.ico")
  
  //Seo用metaを設定
  setSeo()

  //metaを設定
  setMeta()
})

// metaストアの変化を監視
metaInfo.$subscribe(() => {
  setMeta()
})

</script>
