import { defineStore } from 'pinia'

//メタ用ストア
export const useStoreMetaInfo = defineStore('metaInfo', {
  state: () => ({
    title: '',
    description: '',
    keywords: '',
    image:''
  }),
})