import { useStoreConfig } from '@/stores/config'

//ドメイン名
let domainName = ''

//ダミーイメージを設定
export const altImageDumy = (event) => {
  if(!domainName){
    const config = useStoreConfig();
    domainName = config.templateDomainName;
  }
  event.target.onerror=null
  event.target.src=`/${domainName}/images/lotteries/lot_dummy.png`
}

//画像ファイルをインポートする
export const getImage = (fileName) => {
  if(!domainName){
    const config = useStoreConfig();
    domainName = config.templateDomainName;
  }
  return `/${domainName}/images/${fileName}`
}
