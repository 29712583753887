import { defineStore } from 'pinia'

//グローバル変数用ストア
export const useStoreMessages = defineStore('messages', {
  state: () => ({
    messages: []
  }),
  actions: {
    
    add(_message) {
      if (this.messages.length == 0) {
        this.messages.push(_message)
      }
    },
    reset() {
      this.messages = []
    }
  }
})