<template>
<ol v-if="checkUrl()" class="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
  <!-- ホームはデフォルト表示 -->
  <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
    <router-link :to="{name:'home'}" itemprop="item">
      <span itemprop="name">ホーム</span>
    </router-link>
    <meta itemprop="position" content="1" />
  </li>

  <template v-for="(breadcrumb,index) in breadcrumbInfo.breadcrumbList " :key="index">
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
      <template v-if="breadcrumb.link">
        <router-link :to="breadcrumb.link" itemprop="item">
          <span itemprop="name">{{ breadcrumb.title }}</span>
        </router-link>
      </template>
      <template v-else>
        <span itemprop="name">{{ breadcrumb.title }}</span>
      </template>

      <meta itemprop="position" :content=" index + 2 " />
    </li>
  </template>

</ol>

</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { importCssFiles, removeCssFiles } from '@/utils/cssUtil'
import { useStoreBreadcrumbInfo } from '@/stores/breadcrumbInfo'
const breadcrumbInfo = useStoreBreadcrumbInfo()

const route = useRoute()

//パンくずを表示しないURLのリスト
const excludedRoutes = ["/"];

//styleファイル一覧
const styleFileList = [
  'breadcrumb.css'
]

//style link一覧
let styleLinkList = []

//現在のURLがパンくず非表示のURLかチェック
function checkUrl(){
  const isExcludedRoute = excludedRoutes.includes(route.path)
  return !isExcludedRoute
}

onMounted( () => {
  //CSSファイルをインポート
  styleLinkList = importCssFiles(styleFileList)
})

onUnmounted(() => {
  //CSSファイルをを削除
  removeCssFiles(styleLinkList)
})

</script>

