import { useStoreConfig } from '@/stores/config'

//ドメイン名
let domainName = ''

//htmlファイル名取得
export const getHtmlFileName = (fileName) => {
  if(!domainName){
    const config = useStoreConfig();
    domainName = config.templateDomainName;
  }
  return `/${domainName}/html/${fileName}`
}

//外部htmlのリンクの処理
export const handleLinkClick = (event, router) => {
  const target = event.target
  if( target.tagName === 'A' ) {
    event.preventDefault()
    const href = target.getAttribute('href')
    if( href.startsWith('#') ) {
      const sectionId = href.slice(1)
      const section = document.getElementById(sectionId)
      if( section ) {
        section.scrollIntoView({ behavior: 'smooth' })
      }
    }
    else {
      router.push(href)
    }
  }
}

//画像パス置換
export const replaceImagesPath = (htmlContent) => {
  if(!domainName){
    const config = useStoreConfig();
    domainName = config.templateDomainName;
  }
  return htmlContent.replace(/{{imagesPath}}/g, `/${domainName}/images`)
}